
*, *::before, *::after {
    box-sizing: inherit;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    padding: 0;
    margin: 0;
    overflow-y: inherit;
}
body {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.87);
    line-height: 1.8em;
    font-family: "Open Sans",Helvetica,Arial,Verdana,sans-serif;
    font-weight: 400;
    font-size: 1rem;
    background-color: #F7F7F9;
}
